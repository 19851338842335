import { SamplingPoint } from "@/types/samplingpoint.type";
import { Estimations } from "./../types/estimation.type";
import { Image } from "./../types/Image.type";
import { Pagination } from "./../types/pagination.type";
import { responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";

export const getLastImageWithParameters = (samplingPointId: number): Promise<Estimations> => {
  return axios
    .get<Estimations>(`/image/last?samplingPoint=${samplingPointId}`)
    .then(responseHandlerDefault);
};

export const getLastImageStation = (
  codeStation: string,
  codeCamera: string | undefined,
  numCamera: number | undefined
): Promise<Image | undefined> => {
  const params: { codecamera?: string; numcamera?: number } = {};
  if (codeCamera) {
    params.codecamera = codeCamera;
  }
  if (numCamera) {
    params.numcamera = numCamera;
  }

  return axios.get(`/image/lastimage/${codeStation}`, { params }).then(responseHandlerDefault);
};

/**
 * get last images
 * @param stationId
 * @param page
 * @param size
 * @returns
 */
export const getLastImages = (
  stationId: string,
  page = 1,
  size = 10,
  camera?: string | undefined
): Promise<Pagination<Image>> => {
  const params: { page?: number; size?: number; camera?: string } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }

  if (camera) {
    params.camera = camera;
  }

  return axios.get(`/image/getLastImages/${stationId}`, { params }).then(responseHandlerDefault);
};
/**
 * get last images
 * @param stationId
 * @param page
 * @param size
 * @param fromDate
 * @param toDate
 * @returns
 */
export const getLastImagesByDate = (
  stationId: string,
  page = 1,
  size = 10,
  camera?: string | undefined,
  fromDate?: Date | undefined,
  toDate?: Date | undefined
): Promise<Pagination<Image>> => {
  const params: {
    page?: number;
    size?: number;
    camera?: string;
    fromDate?: string;
    toDate?: string;
  } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }

  if (camera) {
    params.camera = camera;
  }
  if (fromDate) {
    params.fromDate = fromDate.toISOString();
  }
  if (toDate) {
    params.toDate = toDate.toISOString();
  }

  return axios
    .get(`/image/getLastImagesByDate/${stationId}`, { params })
    .then(responseHandlerDefault);
};

/**
 * get image
 * @param file
 * @param thumbnail
 * @returns
 */
export const getImage = (file: string, thumbnail = true): Promise<string> => {
  return axios
    .get(`/image/${thumbnail ? "file-thumbnail" : "file"}${file}`, { responseType: "blob" })
    .then((res) => {
      return URL.createObjectURL(responseHandlerDefault(res));
    });
};

/**
 * get last images
 * @param stationId
 * @param page
 * @param size
 * @returns
 */
export const getSamplingPointImages = (
  samPointId: string,
  page = 1,
  size = 10
): Promise<Pagination<Image>> => {
  const params: { page?: number; size?: number } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  return axios
    .get(`/image/getLastImagesPoint/${samPointId}`, { params })
    .then(responseHandlerDefault);
};

export const getImageGraph = (station: string, dateTime: Date): Promise<Image> => {
  const params: { station: string; dateTime: string } = {
    station: station,
    dateTime: dateTime.toISOString(),
  };
  return axios.get(`/image`, { params }).then(responseHandlerDefault);
};

export const getImageByFile = (fileName: string): Promise<Image> => {
  const params: { fileName: string } = {
    fileName: fileName,
  };
  return axios.get(`/image/imageByFile`, { params }).then(responseHandlerDefault);
};

export const sendManualImage = (data: unknown, station: string): Promise<unknown> => {
  if (!station) {
    throw Error("station is required");
  }

  return axios
    .post(`/image/addManual/${station}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(responseHandlerDefault);
};

export const downloadImages = (
  //server: string,
  nameZip: string
): Promise<any> => {
  const params: { name: string } = { name: nameZip };

  return axios
    .get(`/image/downloadImages`, { params, responseType: "arraybuffer" })
    .then(responseHandlerDefault);
};

export const generateZip = (
  samplingPoint: string,
  camera: string,
  labelling: string,
  fromDate: Date,
  toDate: Date
): Promise<string> => {
  const params: {
    //server: string;
    samplingPoint: string;
    camera: string;
    labelling: string;
    fromDate: string;
    toDate: string;
  } = {
    //server: server,
    samplingPoint: samplingPoint,
    camera: camera,
    labelling: labelling,
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
  };
  return axios.get(`/image/generateZip`, { params }).then(responseHandlerDefault);
};
