const UTMScaleFactor: any = 0.9996;
const pi: any = 3.14159265358979;
const sm_a = 6378137.0;
const sm_b = 6356752.314;

export const UTMXYToLatLon = (x: any, y: any, zone: any, southhemi: any, latlon: any): any => {
  x -= 500000.0;
  x /= UTMScaleFactor;

  /* If in southern hemisphere, adjust y accordingly. */
  if (southhemi !== "N") {
    y -= 10000000.0;
  }

  y /= UTMScaleFactor;

  const cmeridian = UTMCentralMeridian(zone);
  MapXYToLatLon(x, y, cmeridian, latlon);
};

/* GPS to LatLon */
export const GPSToLatLon = (
  /**
   * lat = ddmm.mmmmm
   * log = dddmm.mmmmm
   * decimal = degrees + minutes / 60
   */

  x: number,
  cardinalPointX: string,
  y: number,
  cardinalPointY: string
): Array<number> => {
  if (x > 9999 || y > 99999) {
    const lat_error = 0;
    const log_error = 0;
    return [lat_error, log_error];
  } else {
    /* Recogida de grados del dato */
    const lat_degree = x / 100;
    const log_degree = y / 100;
    /*Recogida de minutos del dato */
    const lat_minutes = x % 100;
    const log_minutes = x % 100;
    /*Representacion*/
    let lat_converted = lat_degree + lat_minutes / 60;
    let log_converted = log_degree + log_minutes / 60;
    /* If cardinalPoint is S or W, adjust and accordingly. */
    if (cardinalPointX !== "N") {
      lat_converted = lat_converted * -1;
    }
    if (cardinalPointY !== "E") {
      log_converted = log_converted * -1;
    }

    return [lat_converted, log_converted];
  }
};

export function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371; // Radius of the earth in km
  const dLat = DegToRad(lat2 - lat1); // deg2rad below
  const dLon = DegToRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(DegToRad(lat1)) * Math.cos(DegToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function UTMCentralMeridian(zone: any): any {
  return DegToRad(-183.0 + zone * 6.0);
}

function DegToRad(deg: any) {
  return (deg / 180.0) * pi;
}
export const RadToDeg = (rad: any): any => {
  return (rad / pi) * 180.0;
};

function MapXYToLatLon(x: any, y: any, lambda0: any, philambda: any) {
  let Nfpow;

  /* Get the value of phif, the footpoint latitude. */
  const phif = FootpointLatitude(y);

  /* Precalculate ep2 */
  const ep2 = (Math.pow(sm_a, 2.0) - Math.pow(sm_b, 2.0)) / Math.pow(sm_b, 2.0);

  /* Precalculate cos (phif) */
  const cf = Math.cos(phif);

  /* Precalculate nuf2 */
  const nuf2 = ep2 * Math.pow(cf, 2.0);

  /* Precalculate Nf and initialize Nfpow */
  const Nf = Math.pow(sm_a, 2.0) / (sm_b * Math.sqrt(1 + nuf2));
  Nfpow = Nf;

  /* Precalculate tf */
  const tf = Math.tan(phif);
  const tf2 = tf * tf;
  const tf4 = tf2 * tf2;

  /* Precalculate fractional coefficients for x**n in the equations
             below to simplify the expressions for latitude and longitude. */
  const x1frac = 1.0 / (Nfpow * cf);

  Nfpow *= Nf; /* now equals Nf**2) */
  const x2frac = tf / (2.0 * Nfpow);

  Nfpow *= Nf; /* now equals Nf**3) */
  const x3frac = 1.0 / (6.0 * Nfpow * cf);

  Nfpow *= Nf; /* now equals Nf**4) */
  const x4frac = tf / (24.0 * Nfpow);

  Nfpow *= Nf; /* now equals Nf**5) */
  const x5frac = 1.0 / (120.0 * Nfpow * cf);

  Nfpow *= Nf; /* now equals Nf**6) */
  const x6frac = tf / (720.0 * Nfpow);

  Nfpow *= Nf; /* now equals Nf**7) */
  const x7frac = 1.0 / (5040.0 * Nfpow * cf);

  Nfpow *= Nf; /* now equals Nf**8) */
  const x8frac = tf / (40320.0 * Nfpow);

  /* Precalculate polynomial coefficients for x**n.
             -- x**1 does not have a polynomial coefficient. */
  const x2poly = -1.0 - nuf2;

  const x3poly = -1.0 - 2 * tf2 - nuf2;

  const x4poly =
    5.0 +
    3.0 * tf2 +
    6.0 * nuf2 -
    6.0 * tf2 * nuf2 -
    3.0 * (nuf2 * nuf2) -
    9.0 * tf2 * (nuf2 * nuf2);

  const x5poly = 5.0 + 28.0 * tf2 + 24.0 * tf4 + 6.0 * nuf2 + 8.0 * tf2 * nuf2;

  const x6poly = -61.0 - 90.0 * tf2 - 45.0 * tf4 - 107.0 * nuf2 + 162.0 * tf2 * nuf2;

  const x7poly = -61.0 - 662.0 * tf2 - 1320.0 * tf4 - 720.0 * (tf4 * tf2);

  const x8poly = 1385.0 + 3633.0 * tf2 + 4095.0 * tf4 + 1575 * (tf4 * tf2);

  /* Calculate latitude */
  philambda[0] =
    phif +
    x2frac * x2poly * (x * x) +
    x4frac * x4poly * Math.pow(x, 4.0) +
    x6frac * x6poly * Math.pow(x, 6.0) +
    x8frac * x8poly * Math.pow(x, 8.0);

  /* Calculate longitude */
  philambda[1] =
    lambda0 +
    x1frac * x +
    x3frac * x3poly * Math.pow(x, 3.0) +
    x5frac * x5poly * Math.pow(x, 5.0) +
    x7frac * x7poly * Math.pow(x, 7.0);
}

function FootpointLatitude(y: any) {
  /* Precalculate n (Eq. 10.18) */
  const n = (sm_a - sm_b) / (sm_a + sm_b);

  /* Precalculate alpha_ (Eq. 10.22) */
  /* (Same as alpha in Eq. 10.17) */
  const alpha_ = ((sm_a + sm_b) / 2.0) * (1 + Math.pow(n, 2.0) / 4 + Math.pow(n, 4.0) / 64);

  /* Precalculate y_ (Eq. 10.23) */
  const y_ = y / alpha_;

  /* Precalculate beta_ (Eq. 10.22) */
  const beta_ =
    (3.0 * n) / 2.0 + (-27.0 * Math.pow(n, 3.0)) / 32.0 + (269.0 * Math.pow(n, 5.0)) / 512.0;

  /* Precalculate gamma_ (Eq. 10.22) */
  const gamma_ = (21.0 * Math.pow(n, 2.0)) / 16.0 + (-55.0 * Math.pow(n, 4.0)) / 32.0;

  /* Precalculate delta_ (Eq. 10.22) */
  const delta_ = (151.0 * Math.pow(n, 3.0)) / 96.0 + (-417.0 * Math.pow(n, 5.0)) / 128.0;

  /* Precalculate epsilon_ (Eq. 10.22) */
  const epsilon_ = (1097.0 * Math.pow(n, 4.0)) / 512.0;

  /* Now calculate the sum of the series (Eq. 10.21) */

  return (
    y_ +
    beta_ * Math.sin(2.0 * y_) +
    gamma_ * Math.sin(4.0 * y_) +
    delta_ * Math.sin(6.0 * y_) +
    epsilon_ * Math.sin(8.0 * y_)
  );
}
