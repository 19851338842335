
import { defineComponent, ref } from "vue";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { formatParameterName } from "@/utils/parameter.util";
import { getSamplingPoints } from "@/service/samplingpoint.service";
import { RadToDeg, UTMXYToLatLon, getDistanceFromLatLonInKm } from "@/utils/coordinates.util";
import { sendManualImage } from "@/service/image.service";
import { ElMessage } from "element-plus/lib/components";
import jsQR from "jsqr";
import { Station } from "@/types";

export default defineComponent({
  setup() {
    // - capture data
    const video = document.createElement("video");
    const canvas = ref<InstanceType<typeof HTMLCanvasElement> | null>(null);
    let canvas2d!: CanvasRenderingContext2D | null;
    const qrSize = 100;
    const qrPosition = { x: 4, y: 4 };
    const qrIsValid = ref(false);
    let animFrameId!: number;
    const scanRuning = ref(false);

    const format = formatParameterName;
    return {
      format,
      canvas,
      capture: ref({
        video,
        canvas,
        canvas2d,
        qrSize,
        qrPosition,
        qrIsValid,
        animFrameId,
        scanRuning,
      }),
    };
  },
  data() {
    return {
      form: new FormData(),
      image: "" as string,
      samplingPoint: undefined as SamplingPoint | undefined,
      loading: false,
      samplingpoints: [] as SamplingPoint[],
      disableCamera: true,
    };
  },
  mounted() {
    // - samplingpoints active and with station
    getSamplingPoints(
      undefined,
      undefined,
      {
        fields: {
          station: { value: "", operator: "is not null" },
          "samplingPoint.status": { value: true, operator: "equal" },
          "station.active": { value: true, operator: "equal" },
        },
      },
      {
        "samplingPoint.name": "ASC",
      }
    ).then((res) => (this.samplingpoints = res.data));
  },
  watch: {
    samplingPoint: function () {
      this.checkUserLocation();
    },
  },
  computed: {
    visibleDialog: {
      get(): boolean {
        return !!this.image;
      },
      set(value: boolean) {
        if (!value) this.image = "";
      },
    },
  },
  methods: {
    checkUserLocation() {
      const options = {
        enableHighAccuracy: true,
      };

      navigator.geolocation.getCurrentPosition(
        (pos) => {
          if (!this.samplingPoint) {
            return;
          }
          let latlon = new Array(2);
          UTMXYToLatLon(
            this.samplingPoint.utmX,
            this.samplingPoint.utmY,
            this.samplingPoint.timeZone,
            "N",
            latlon
          );

          const distance = getDistanceFromLatLonInKm(
            RadToDeg(latlon[0]),
            RadToDeg(latlon[1]),
            pos.coords.latitude,
            pos.coords.longitude
          );

          this.disableCamera = distance * 1000 > pos.coords.accuracy + 100;
          if (this.disableCamera) {
            ElMessage.error(this.$t("error.invalidLocation"));
            this.captureStop();
          } else {
            this.captureStart();
          }
        },
        () => {
          ElMessage.warning(this.$t("info.locationRequired"));
        },
        options
      );
    },
    async captureTake() {
      if (!this.capture.canvas || !this.capture.canvas2d) return;

      // resize canvas to take minimal resolution capture
      const width = 2592;
      const height = 1944;

      const scaleHeight = height / this.capture.canvas.height;
      const scaleWidth = width / this.capture.canvas.width;
      const scale = scaleHeight >= scaleWidth ? scaleHeight : scaleWidth;

      this.capture.canvas.width = this.capture.canvas.width * scale;
      this.capture.canvas.height = this.capture.canvas.height * scale;

      this.capture.canvas2d.drawImage(
        this.capture.video,
        0,
        0,
        this.capture.canvas.width,
        this.capture.canvas.height
      );
      this.image = this.capture.canvas.toDataURL("image/jpeg");
    },
    handleSubmit() {
      if (this.samplingPoint && this.samplingPoint.id) {
        this.loading = true;
        const date = new Date();
        this.form.append(
          "file",
          this.dataURLToFile(
            this.image,
            `${date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()}${
              date.getHours() >= 10 ? date.getHours() : "0" + date.getHours()
            }${date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes()}${
              date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds()
            }.jpeg`
          )
        );
        sendManualImage(this.form, (this.samplingPoint.station as Station).station || "")
          .then(() => {
            ElMessage.success(this.$t("success.action"));
          })
          .catch(() => {
            ElMessage.error(this.$t("error.any"));
          })
          .finally(() => {
            this.form = new FormData();
            this.image = "";
            this.samplingPoint = undefined;
            this.disableCamera = true;
            this.loading = false;
            this.captureStop();
          });
      }
    },
    dataURLToFile(dataURL: string, filename: string) {
      const parts = dataURL.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = Buffer.from(parts[1], "base64");
      return new File([raw], filename, { type: contentType });
    },
    captureStart() {
      // Use facingMode: environment to attemt to get the front camera on phones
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then((stream) => {
          this.capture.video.srcObject = stream;
          this.capture.video.setAttribute("playsinline", ""); // required to tell iOS safari we don't want fullscreen
          this.capture.video.play();
          this.capture.canvas2d = null;
          this.capture.scanRuning = true;
          //outputData = null;
          //imageSrc = null;
          this.capture.animFrameId = requestAnimationFrame(this.captureTick);
        })
        .catch((err) => {
          if ((err?.name || "").includes("NotAllowedError")) {
            ElMessage.warning(this.$t("info.cameraRequired"));
          } else {
            ElMessage.error(this.$t("error.any"));
          }
        });
    },
    captureStop() {
      if (!this.capture.video.srcObject) return;
      var stream = this.capture.video.srcObject as MediaStream;
      var tracks = stream.getTracks();
      this.capture.video.pause();
      tracks.forEach(function (track) {
        track.stop();
      });

      cancelAnimationFrame(this.capture.animFrameId);
      this.capture.video.srcObject = null;
      this.capture.scanRuning = false;
    },
    captureTick() {
      if (!this.capture.scanRuning) return;

      if (this.capture.canvas && !this.capture.canvas2d) {
        this.capture.canvas2d = this.capture.canvas.getContext("2d");
      }
      if (
        this.capture.video.readyState === this.capture.video.HAVE_ENOUGH_DATA &&
        this.capture.canvas &&
        this.capture.canvas2d
      ) {
        this.capture.canvas.height = this.capture.video.videoHeight;
        this.capture.canvas.width = this.capture.video.videoWidth;
        this.capture.canvas2d.drawImage(
          this.capture.video,
          0,
          0,
          this.capture.canvas.width,
          this.capture.canvas.height
        );
        // - lineas para la lectura del qr
        this.captureDrawRect(
          this.capture.canvas2d,
          this.capture.qrPosition,
          { dx: this.capture.qrSize, dy: this.capture.qrSize },
          "rgba(0, 000, 255, 0.4)",
          4
        );
        var imageData = this.capture.canvas2d.getImageData(
          this.capture.qrPosition.x,
          this.capture.qrPosition.y,
          this.capture.qrSize,
          this.capture.qrSize
        );

        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          var loc = code.location;
          this.captureDrawPath(
            this.capture.canvas2d,
            this.capture.qrPosition,
            [
              loc.topLeftCorner,
              loc.topRightCorner,
              loc.bottomRightCorner,
              loc.bottomLeftCorner,
              loc.topLeftCorner,
            ],
            "#00FF00",
            4
          );

          //this.capture.outputData = code;
          this.capture.qrIsValid = true;
        } else {
          //outputData = null;
          this.capture.qrIsValid = false;
        }
      }
      this.capture.animFrameId = requestAnimationFrame(this.captureTick);
    },
    captureDrawPath(
      canvas2d: CanvasRenderingContext2D,
      offset: { x: number; y: number },
      points: { x: number; y: number }[],
      color: string,
      lineWidth: number
    ) {
      canvas2d.beginPath();
      canvas2d.lineWidth = lineWidth;
      canvas2d.strokeStyle = color;
      canvas2d.moveTo(points[0].x + offset.x, points[0].y + offset.y);
      for (var i = 1; i < points.length; i++)
        canvas2d.lineTo(points[i].x + offset.x, points[i].y + offset.y);
      canvas2d.stroke();
    },
    captureDrawRect(
      canvas2d: CanvasRenderingContext2D,
      topLeft: { x: number; y: number },
      size: { dx: number; dy: number },
      color: string,
      lineWidth: number
    ) {
      canvas2d.beginPath();
      canvas2d.lineWidth = lineWidth;
      canvas2d.strokeStyle = color;
      canvas2d.rect(
        topLeft.x - lineWidth / 2,
        topLeft.y - lineWidth / 2,
        size.dx + lineWidth,
        size.dy + lineWidth
      );
      // - marco
      canvas2d.stroke();
    },
  },
});
